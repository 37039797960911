<template>
  <b-container class="mt-4">
    <b-card
      header-html="<label class='text-white'>Onay Bekleyen Personeller</label>"
      header-class="header"
    >
      <div class="mt-4">
        <PMSTable
          :tableItems="getPersonel"
          @onPageChanged="onPersonelPageChanged"
          :nameWithSurname="true"
          :editable="true"
          :erasable="true"
          :tableFields="getPersonelFields"
          deleteMessage="Personeli silmek istediğinizden emin misiniz? Personelin katıldığı yarışma grupları da silinecektir!"
          :pageCount="getPersonelTotalPageCount"
          hasAction="personel"
          @openDetail="openDetail($event)"
          @editClick="editClick($event)"
          @multipleDeleteAction="deleteMultiplePersonel"
          @multipleApproveAction="approveMultiplePersonel"
          :actionModes="['Sil', 'Onayla']"
        ></PMSTable>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import PMSTable from "../../components/tables/GeneralTable.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PMSTable,
  },
  computed: {
    ...mapGetters([
      "getPersonel",
      "getPersonelFields",
      "getPersonelTotalPageCount",
    ]),
  },
  mounted() {
    this.$store.dispatch("initUnapprovedPersonel");
    this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
  },
  data() {
    return {
      currentTab: 0,
      personalFields: [],
      personalItems: [],
      sortingSelectMode: "Tümünü Getir",
    };
  },
  methods: {
    setPage(val) {
      this.currentCard = val;
    },
    getColor(color) {
      if (this.currentCard == color) {
        return "color:white";
      } else {
        return "color:black";
      }
    },
    onPersonelPageChanged(value) {
      this.$store.dispatch("initUnapprovedPersonel", value);
    },
    editClick(index) {
      this.$router.push({
        name: "EditPersonel",
        params: { id: this.getPersonel[index].id },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    openDetail(index) {
      this.$router.push({
        name: "PersonelDetail",
        params: { id: this.getPersonel[index].id },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    approveMultiplePersonel(indexes) {
      this.$store.dispatch("approveUnapprovedPersonel", indexes);
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    deleteMultiplePersonel(indexes) {
      this.$store.dispatch("deleteUnapprovedPersonel", indexes);
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.selection-field {
  max-width: 16rem;
  min-width: 16rem;
}
.align-right {
  margin-right: 1rem;
}
.tab {
  color: white;
  background: gray;
}
.pageButton {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.buttonBar {
  text-align: end;
  margin-top: -3.6rem;
}
.dark {
  color: black;
}
</style>